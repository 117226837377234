

















































































































































































































































































































































































































































































































import { API, Config } from 'client-website-ts-library/services';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PrivacyPolicy extends Vue {
  private businessName: string | null = null;

  mounted() {
    API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
      this.businessName = franchise.Name;
    });
  }
}
